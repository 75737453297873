import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FooterForm from "../components/footerForm"
const ContactPage = () => {
  return (
    <Layout isContact={true} >
      <SEO
        title="Contact Us | Digital E-commerce Agency In India"
        description="Explore full service e-commerce design, development and growth solutions in India and North America. Tell us more about your business and we’ll handhold you all the way."
      />
      <div className="contact-wrapper">
        <FooterForm isContactPage={true} />
      </div>
    </Layout>
  )
}

export default ContactPage
